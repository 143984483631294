import React from 'react';

const ReactDevelopment = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.9139 42H5.08689C4.00302 42 2.96371 41.5686 2.1973 40.8009C1.43088 40.0333 1 38.9921 1 37.9064V9.0934C1 8.00774 1.43088 6.96655 2.1973 6.19887C2.96371 5.4312 4.00302 5 5.08689 5H42.9139C43.9978 5 45.0371 5.4312 45.8035 6.19887C46.5699 6.96655 47 8.00774 47 9.0934V37.9382C46.9916 39.0183 46.5574 40.0513 45.7919 40.8122C45.0264 41.573 43.9923 42 42.9139 42ZM5.08689 6.58662C4.42312 6.58662 3.7866 6.85073 3.31725 7.32085C2.84789 7.79098 2.58402 8.42854 2.58402 9.0934V37.9382C2.58402 38.603 2.84789 39.2406 3.31725 39.7107C3.7866 40.1808 4.42312 40.445 5.08689 40.445H42.9139C43.5776 40.445 44.2142 40.1808 44.6835 39.7107C45.1529 39.2406 45.416 38.603 45.416 37.9382V9.12516C45.416 8.4603 45.1529 7.82274 44.6835 7.35262C44.2142 6.88249 43.5776 6.61838 42.9139 6.61838L5.08689 6.58662Z"
      fill="#475467"
    />
    <path
      d="M46.208 13.1218H1.85549C1.64543 13.1218 1.44404 13.0381 1.29551 12.8894C1.14698 12.7406 1.06348 12.5389 1.06348 12.3285C1.06348 12.1181 1.14698 11.9163 1.29551 11.7676C1.44404 11.6188 1.64543 11.5352 1.85549 11.5352H46.208C46.4181 11.5352 46.6195 11.6188 46.768 11.7676C46.9165 11.9163 47.0001 12.1181 47.0001 12.3285C47.0001 12.5389 46.9165 12.7406 46.768 12.8894C46.6195 13.0381 46.4181 13.1218 46.208 13.1218Z"
      fill="#475467"
    />
    <path
      d="M42.5657 9.95003C42.4605 9.95459 42.3552 9.93714 42.2571 9.8989C42.1589 9.86066 42.0702 9.80245 41.9957 9.72788C41.9236 9.65807 41.8667 9.57415 41.8286 9.48133C41.7904 9.38851 41.7716 9.28884 41.7737 9.18849C41.7398 9.08543 41.7398 8.9743 41.7737 8.87124C41.8075 8.77614 41.8617 8.68948 41.9322 8.61733C42.0839 8.47908 42.2817 8.40234 42.4868 8.40234C42.6919 8.40234 42.8889 8.47908 43.0406 8.61733C43.1111 8.68948 43.1653 8.77614 43.1991 8.87124C43.233 8.9743 43.233 9.08543 43.1991 9.18849C43.1991 9.39047 43.119 9.58409 42.9764 9.72691C42.8338 9.86974 42.6405 9.95003 42.4388 9.95003H42.5657Z"
      fill="#475467"
    />
    <path
      d="M39.3978 9.94942C39.2926 9.95397 39.1874 9.93652 39.0892 9.89829C38.9911 9.86005 38.9023 9.80183 38.8278 9.72727C38.6954 9.57758 38.617 9.38756 38.6058 9.18787C38.5881 9.08284 38.5881 8.97566 38.6058 8.87063C38.5903 8.78672 38.5903 8.70062 38.6058 8.61671C38.7626 8.47622 38.9655 8.39844 39.1759 8.39844C39.3863 8.39844 39.5898 8.47622 39.7467 8.61671C39.8172 8.68886 39.8706 8.77553 39.9044 8.87063C39.9222 8.97566 39.9222 9.08284 39.9044 9.18787C39.8932 9.38756 39.8157 9.57758 39.6832 9.72727C39.6074 9.82318 39.5093 9.89939 39.3978 9.94942Z"
      fill="#475467"
    />
    <path
      d="M36.5145 9.95003C36.4141 9.9541 36.3136 9.93647 36.2206 9.89812C36.1277 9.85978 36.0439 9.80162 35.9754 9.72788C35.8241 9.58791 35.7338 9.39447 35.7225 9.18849C35.7048 9.08346 35.7048 8.97627 35.7225 8.87124C35.7674 8.77419 35.8316 8.68764 35.912 8.61733C36.0637 8.47908 36.2615 8.40234 36.4666 8.40234C36.6716 8.40234 36.8695 8.47908 37.0211 8.61733L37.2114 8.87124C37.2286 8.97632 37.2286 9.08341 37.2114 9.18849C37.2135 9.28884 37.1947 9.38851 37.1565 9.48133C37.1183 9.57415 37.0614 9.65807 36.9894 9.72788C36.8623 9.85603 36.6942 9.93478 36.5145 9.95003Z"
      fill="#475467"
    />
    <path
      d="M18.647 32.5424C18.4318 32.5328 18.2284 32.4421 18.077 32.2885L13.2932 27.4971C13.2175 27.4259 13.1574 27.3399 13.1161 27.2444C13.0748 27.1489 13.0535 27.0457 13.0535 26.9416C13.0535 26.8376 13.0748 26.7346 13.1161 26.6391C13.1574 26.5436 13.2175 26.4576 13.2932 26.3864L18.077 21.5632C18.1509 21.4864 18.2397 21.4252 18.3377 21.3835C18.4356 21.3417 18.5406 21.3203 18.647 21.3203C18.7535 21.3203 18.8592 21.3417 18.9572 21.3835C19.0552 21.4252 19.1432 21.4864 19.2171 21.5632C19.3551 21.7151 19.4321 21.9131 19.4321 22.1185C19.4321 22.3239 19.3551 22.5218 19.2171 22.6737L14.9724 26.926L19.2171 31.178C19.3551 31.3299 19.4321 31.5279 19.4321 31.7333C19.4321 31.9387 19.3551 32.1366 19.2171 32.2885C19.0657 32.4421 18.8623 32.5328 18.647 32.5424Z"
      fill="#475467"
    />
    <path
      d="M29.3541 32.5428C29.2469 32.5414 29.1405 32.5183 29.0424 32.4747C28.9444 32.431 28.8563 32.3677 28.7833 32.2889C28.7076 32.2177 28.6475 32.1317 28.6062 32.0362C28.5649 31.9407 28.5436 31.8377 28.5436 31.7337C28.5436 31.6296 28.5649 31.5266 28.6062 31.4311C28.6475 31.3356 28.7076 31.2496 28.7833 31.1784L33.0288 26.9264L28.7833 22.6741C28.6926 22.6059 28.618 22.519 28.5637 22.4193C28.5094 22.3195 28.4766 22.2092 28.4685 22.0958C28.4605 21.9824 28.4768 21.8686 28.5165 21.7621C28.5562 21.6556 28.6188 21.5589 28.699 21.4786C28.7793 21.3982 28.8758 21.336 28.9821 21.2963C29.0884 21.2566 29.2015 21.24 29.3147 21.2481C29.4279 21.2561 29.5384 21.2886 29.638 21.343C29.7376 21.3974 29.8244 21.4727 29.8925 21.5636L34.7079 26.3868C34.7816 26.4553 34.8398 26.539 34.8781 26.6322C34.9164 26.7253 34.934 26.8257 34.9299 26.9264C34.9345 27.0318 34.9171 27.1369 34.8789 27.2353C34.8407 27.3336 34.7824 27.4229 34.7079 27.4975L29.8925 32.2889C29.8256 32.3668 29.7432 32.4298 29.6504 32.4735C29.5576 32.5173 29.4567 32.5409 29.3541 32.5428Z"
      fill="#475467"
    />
    <path
      d="M21.6251 34.7664H21.3722C21.2729 34.7384 21.1806 34.6898 21.1015 34.6234C21.0224 34.5571 20.9585 34.4747 20.9136 34.3817C20.8686 34.2887 20.8437 34.1873 20.8409 34.0841C20.838 33.9808 20.8568 33.878 20.8965 33.7827L25.934 19.4079C25.9672 19.3071 26.0205 19.2142 26.0902 19.1344C26.16 19.0545 26.2448 18.9894 26.3401 18.943C26.4353 18.8966 26.5383 18.87 26.644 18.8644C26.7498 18.8588 26.8562 18.8744 26.9557 18.9105C27.0553 18.9466 27.1464 19.0023 27.2241 19.0743C27.3018 19.1464 27.3646 19.2333 27.4082 19.33C27.4518 19.4267 27.4752 19.5311 27.4778 19.6372C27.4804 19.7432 27.4617 19.8488 27.4229 19.9474L22.3854 34.2903C22.3151 34.4324 22.2072 34.5522 22.073 34.6362C21.9387 34.7203 21.7834 34.7653 21.6251 34.7664Z"
      fill="#475467"
    />
  </svg>
);

export default ReactDevelopment;
